import JustValidate from 'just-validate/dist/js/just-validate.min';
import { isReCaptchaValid, removeRecaptchaError, setRecaptchaError } from './ReCaptchaActions';
import HystModal from 'hystmodal';
import cloneDeep from 'lodash.clonedeep';
import { globalEventProperties } from '../../../../../../Common/Resources/src_front/js/mixpannel/globalEventProperties';
import mixpanel from 'mixpanel-browser';

export const initContactForm = () => {
    const formSelector = '[data-contact-us-form]';
    const form = document.querySelector(`${formSelector}`);
    if (!form) return;

    const emailInput = document.querySelector('[data-validate-field="email"]');
    const invalidEmailMessage = emailInput.getAttribute('data-invalid-email');
    const subjectInput = document.querySelector('[data-validate-field="subject"]');
    const invalidSubjectMessage = subjectInput.getAttribute('data-invalid-subject');
    const messageInput = document.querySelector('[data-validate-field="text"]');
    const invalidTextMessage = messageInput.getAttribute('data-invalid-message');

    const modalContactForm = new HystModal({
        catchFocus: true,
        waitTransitions: true,
        closeOnEsc: false,
    });

    const formAction = new window.JustValidate(`${formSelector}`, {
        rules: {
            email: {
                required: true,
                email: true,
                strength: {
                    custom: '^([a-z0-9_-]+\\.)*[a-z0-9_-]+@[a-z0-9_-]+(\\.[a-z0-9_-]+)*\\.[a-z]{2,4}$',
                },
            },
            subject: {
                required: true,
            },
            text: {
                required: true,
            },
        },

        messages: {
            email: invalidEmailMessage,
            subject: invalidSubjectMessage,
            text: invalidTextMessage,
        },

        submitHandler: function (form, values, ajax) {
            const props = cloneDeep(globalEventProperties);
            const email = form.querySelector('[data-mixpanel-email]');

            if (email) {
                props.form_value = email.value.trim();

                mixpanel.people.set({
                    $email: email.value.trim(),
                });
            }

            mixpanel.track('button_click', props);

            if (isReCaptchaValid()) {
                removeRecaptchaError(`${formSelector}`);
            } else {
                setRecaptchaError(`${formSelector}`);
                return;
            }

            ajax({
                url: form.getAttribute('action'),
                method: 'POST',
                data: values,
                async: true,
                callback: function (response) {
                    modalContactForm.open('[data-modal-success]');
                    resetForm(form);
                },
                error: function (response) {
                    modalContactForm.open('[data-modal-error]');
                },
            });
        },

        invalidFormCallback: function (errors) {
            const props = cloneDeep(globalEventProperties);
            const email = form.querySelector('[data-mixpanel-email]');
            let errorText = '';

            props.button_name = 'Submit';

            for (let error in errors) {
                errorText = `${errorText ? errorText + ';' : ''} ${errors[error].message}`;
            }

            if (!isReCaptchaValid()) {
                errorText = `${errorText ? errorText + ';' : ''} 'The field is required'`;
            }

            props.form_validation = errorText;

            if (email) {
                props.form_value = email.value.trim();

                mixpanel.people.set({
                    $email: email.value.trim(),
                });
            }

            mixpanel.track('button_click', props);

            if (errors && !isReCaptchaValid()) {
                setRecaptchaError(`${formSelector}`);
            }
        },
    });

    function resetForm(form) {
        grecaptcha.reset();
        form.reset();
        removeRecaptchaError(`${formSelector}`);
    }
};
