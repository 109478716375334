import Glide from '@glidejs/glide';

export const initSliderReviews = () => {
    const sliderSelector = '[data-slider-reviews]';
    const slider = document.querySelector(`${sliderSelector}`);
    if (!slider) return;

    const glideSlider = new Glide(`${sliderSelector}`, {
        type: 'carousel',
        startAt: 1,
        perView: 1,
        focusAt: 0,
    });

    glideSlider.mount();
};
