export const initHeaderDropdown = () => {
    const headerAccordion = {
        init: function (target) {
            let headers = document.querySelectorAll('.dropdown');
            if (headers.length > 0) {
                for (let head of headers) {
                    head.addEventListener('click', headerAccordion.select);
                }
            }
        },

        select: function () {
            this.classList.toggle('active');
        },
    };

    headerAccordion.init('.header');
};
